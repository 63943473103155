<template>
  <div class="liveLottery">
    <div v-title data-title="真人连胜"></div>

    <!-- <van-nav-bar
      title="真人闯关"
      left-arrow
      :fixed="true"
      @click-left="gotoback"
    /> -->

    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/bg-RealSuperGold.jpg`" />
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img">
        <img src="../assets/img/pcbb/rules_1.png" v-if="cueTheme == 'dattheme'" />
        <img :src="`${imgUrl}pcbb/rules.png`" v-else />
      </div>
      <div class="rule-middle-img">
        <!-- 活动条件 -->
        <div class="rule-condition">
          <div class="rule-condition-title"></div>
          <div class="rule-acontents">
            <div class="title-item">
              <p>活动对象:</p>
              <span style="color:var(--li-colortext);">贝宝体育全站会员</span>
            </div>
            <div class="title-item">
              <p>活动平台:</p>
              <span style="color:var(--li-colortext);">任何真人场馆</span>
            </div>
            <div class="title-item">
              <p>活动时间:</p>
              <span style="color:var(--li-colortext);">长期</span>
            </div>
            <div class="title-item">
              <p>活动条件:</p>
              <span style="color:var(--li-colortext);">连赢8场</span>
            </div>
            <div class="title-item">
              <p>申请规则:</p>
              <span
                style="color:var(--li-colortext);">会员在指定场馆任何真人场馆,中的某一个游戏场馆内投注真人百家乐游戏，完成连赢8场，且满足8局总流水要求，即可获得对应的彩金</span>
            </div>
          </div>
        </div>

        <!-- 活动内容 -->
        <div class="rule-content">
          <div class="rule-content-title"></div>
          <table>
            <thead>
              <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc)">
                <td style="
                                    color: #fff;
                                    border-top-left-radius: 10px;
                                    border-right: 1px solid #e2d2bf;
                                  ">
                  8局比赛总赢金额
                </td>
                <td style="
                                    color: #fff;
                                    border-right: 1px solid #e2d2bf;
                                    border-top-right-radius: 10px;
                                  ">
                  超级彩金
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in datalist" :key="index" v-if="index != 0">
                <td>≥{{ item.beat_limit }}</td>
                <td>{{ item.bonus_money }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title"></div>
          <div class="activity_rules">
            <ul>
              <li>
                <span>1.</span>
                此优惠仅针对在贝宝网站中的任何真人场馆,其中单个平台内投注真人百家乐游戏连续产生连赢的会员，如果投注其它游戏连赢8场，是无法获得此优惠的;
              </li>
              <li>
                <span>2.</span>
                本活动只计算单个游戏场馆内的连胜，如A场馆5连胜，B场馆3连胜，此时最高连胜为A场馆的5连胜并未满足8连胜条件。
              </li>
              <li>
                <span>3.</span>
                此优惠连赢仅计算百家乐游戏中的"庄"/"闲"投注玩法，如果投注百家乐游戏的其它玩法无论输赢均无法参与此优惠。
              </li>
              <li>
                <span>4.</span>
                此会员在百家游戏中同时投注庄、闲将被视为对赌，不被计入有效投注额，同时连赢局数将视为中断。
              </li>
              <li>
                <span>5.</span>
                游戏过程中如出现和局不影响连赢，但是不计算在连赢局数之内。
              </li>
              <li>
                <span>6.</span>
                每日只能申请一次，连赢场次完成后24小时内联系客服进行申请，如会员在连赢完成后24小时内未联系客服进行申请，视为主动放弃该活动奖金。
              </li>
              <li>
                <span>7.</span>
                局号相同的多个注单仅计算下注金额最高的注单，其他注单不计算在连赢场次内。
              </li>
              <li>
                <span>8.</span>
                本活动只针对娱乐性质的会员，同一手机号码、电子邮箱、相同银行卡、同一个IP地址、同一通信地址、同一台设备，只能由一位会员使用，若发现重复行为，将无限期保留审核、扣回礼品及所产生的利润之权利。
              </li>
              <li>
                <span>9.</span>
                活动中任何用户或团体以不正常的方式套取活动优惠，我司保留在不通知的情况下冻结或关闭账号使用的权力，并不退还款项，且用户会被列入黑名单。若发现有套利客户，对冲，或不诚实获取盈利之行为，将取消其优惠资格。
              </li>
              <li>
                <span>10.</span>
                为避免文字争议，此活动遵循一般活动规则与条款，并由贝宝保留最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>
  </div>
</template>

<script>
import { getdata } from '../network/api'

export default {
  data () {
    return {
      datalist: [],
      cueTheme: '',
    }
  },
  methods: {
    gotoback () {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true',
            },
          })
        })
      }
    },
    getdata () {
      let data = '?type=videos'
      getdata(data).then((res) => {
        if (res.status_code === 200) {
          this.datalist = res.message
        }
      })
    },
  },
  mounted () {
    this.getdata()
    this.cueTheme = window.localStorage.getItem('cueTheme')
  },
}
</script>

<style lang="less">
@import '../assets/css/rulestyle.less';

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000;
}

body {
  background-color: #f7f8fa;
}

.liveLottery {
  .activityRules {
    position: relative;
    background-color: #fff;
    margin: 10px 15px;
    // padding-bottom: 1px;
    border-radius: 8px;

    .title {
      width: 100%;
      padding-top: 20px;
      text-align: center;
      color: #b2987f;
      position: relative;
    }

    .activity_rules {
      padding: 20px 10px;
      padding-bottom: 5px;

      ul li {
        color: #000;
        margin-bottom: 16px;
        position: relative;
        padding-left: 70px;
        font-size: 12px;

        >span {
          text-align: center;
          position: absolute;
          top: 0px;
          left: 0;
          font-family: PingFang SC;
          font-weight: 400;
          color: #c8a57e;
        }
      }
    }

    table {
      width: calc(100% - 30px);
      margin: 0 15px;
      text-align: center;
      border-collapse: separate;
      border-spacing: 0;
      background-color: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-family: PingFang SC;
      border-bottom: 2px solid #e2d2bf;
      font-weight: 400;
      color: #684728;

      th,
      td {
        height: 40px;
        padding: 10px 0;
        color: #684728;
        font-size: 13px;
        text-align: center;
      }
    }

    tr:nth-child(1) {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    tr:nth-child(odd) {
      background-color: #f7f6f3;
    }

    td {
      border-right: 2px solid #e2d2bf;
    }

    td:nth-child(1) {
      border-left: 2px solid #e2d2bf;
    }
  }

  .Rules {
    margin: 10px 15px;
    // margin: 0 20px;
    // margin-top: 15px;
    font-family: PingFang SC;
    border-radius: 8px;
    background-color: #fff;

    .title {
      padding-top: 20px;
      width: 100%;
      text-align: center;
      font-family: PingFang SC;
      font-weight: 400;
      color: #c8a57e;
      position: relative;
    }

    .activity_rules {
      padding: 20px 10px;

      ul li {
        color: #000;
        margin-bottom: 16px;
        position: relative;
        padding-left: 18px;
        font-size: 13px;
        line-height: 1.5em;

        >span {
          width: 16px;
          height: 16px;
          text-align: center;
          line-height: 16px;
          border-radius: 50%;
          position: absolute;
          top: 0px;
          left: 0;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
